import React from 'react'

export const PreviewContext = React.createContext({})

const PreviewProvider = ({ previewData, children }) => {
  return (
    <PreviewContext.Provider value={previewData}>
      {children}
    </PreviewContext.Provider>
  )
}

export default PreviewProvider
