import * as React from 'react'
import {
  usePrismicPreview,
  withPreviewResolver,
  WithPreviewResolverProps,
} from 'gatsby-source-prismic'
import { PageProps } from 'gatsby'
import { REPOSITORY_NAME } from '../config/prismic'

import { Layout } from '../components/Layout'
import PreviewProvider from '../context/Preview'

// import Site from './index'

export default function Preview({ ...props }: WithPreviewResolverProps) {
  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: REPOSITORY_NAME,
  })

  return (
    <PreviewProvider previewData={previewData}>
      <Layout>
        {isPreview === false && <h1>Error - not a preview</h1>}
        {/* <Site /> */}
      </Layout>
    </PreviewProvider>
  )
}
